<template>
  <!-- <div></div> -->
  <div 
  class="ElementMainVideo" 
  @click="click()"
  @mouseenter="mouseEnter()"
  :data-indexElement="indexElement"
  :data-indexMedia="indexMedia"
  v-lazyloadingvideo
  :style="{height: height + 'px'}">


    <template v-if="isVisible"> 
  
    <div class="ElementMainVideo--container">

      <video class="ElementMainVideo--container--video" 
      :poster="content.cover"
      loop muted playsinline autoplay ref="videoplayer">
        <source :src="content.video" type="video/mp4">
      </video>

    </div>

    </template>

    <template v-else>
      
      <video class="ElementMainVideo--container--thumb" 
      :poster="content.cover"
      loop muted playsinline autoplay ref="videoplayer">
      </video>

    </template>

  </div>

</template>

<script>
import LazyLoadingVideo from '@/directives/LazyLoadingVideo';

export default {
  name: 'ElementMainVideo',
  directives:{
    LazyLoadingVideo
  },
  props: ['content', 'context', 'indexElement', 'viewerDisplay'],
  data: function(){
    return {
      indexMedia: '',
      isVisible: false,
      height: '',

      coverHeight: '',
      coverWidth: '',
      coverRatio: ''
    }
  },
  watch:{
    viewerDisplay: function(val){
      // console.log(val)
      if (val.isActiv && this.isVisible){
        // console.log('pause')
        this.$refs.videoplayer.pause()
      }

      if (!(val.isActiv) && this.isVisible){
        // console.log('play')
        this.$refs.videoplayer.play()
      }
    },

    isVisible: function(val){
      // console.log('isVisible', val)
      if (val){
        this.whatHeight()
        this.$refs.videoplayer.play()
      }
    }    
  },
  components: {},
  computed:{

  },
  methods: {

    whatHeight: function(){
      this.$el.getBoundingClientRect().width
      this.height = this.$el.getBoundingClientRect().width * this.coverRatio
    },

    initHeight: function(){
      const image = new Image()

      image.onload = () => {

        this.coverHeight = image.height
        this.coverWidth = image.width
        this.coverRatio = this.coverHeight / this.coverWidth
        this.whatHeight()
      }

      image.src = this.content.cover
    },

    mouseEnter: function(){

      // this.$refs.videoplayer.pause()
    },

    click: function(){

      let newState = {
        isActive: true,
        type: 'image',
        content: {
          type: 'image',
          indexElement: this.indexElement,
          indexMedia: this.indexMedia,
          media: this.content.srcset
        }
      }
      this.$emit('send-viewer-state', newState)
    },

    mouseLeave: function(){
      let newState = {
        isActive: false
      }

      this.$emit('send-viewer-state', newState)
    },

    onResize: function(){
      this.whatHeight()
    }
  },
  created(){
    this.indexMedia = this.$store.indexMedia(this.content)

    this.initHeight()
  },

  mounted(){
    window.addEventListener('resize', this.onResize)
  },

  beforeDestroy(){
    window.removeEventListener('resize', this.onResize)
  }
  
}
</script>

<style lang="scss">
@import '@/css/variables';


.ElementMainVideo{

  width: 100%;
  // height: 20vw;
  object-fit: cover;

  padding: calc(var(--home-esp-bloc-small) / 2);
  width: calc(100% - var(--home-esp-bloc-small));
  position: relative;

  &--container{

    position: relative;
    width: 100%;
    // height: 20vw;

    &--video{
      width: 100%;
      object-fit: cover;

      border-radius: var(--home-round-small);

      position: absolute;
    }

    &--thumb{
      position: absolute;
      width: 100%;
      object-fit: cover;
      border-radius: var(--home-round-small);

      // transition: all .5s ease;

      top: 0;
      left: 0;  

      z-index: 200;

      &.isBlur{
        // filter: blur(3px);
      }
    }
  }
}












</style>