import Vue from 'vue';

const LazyLoading = {
	inserted(el, binding, vnode) {
    el, binding, vnode

    setTimeout(() => { 

    let callbackImage = function(entries, observer){
      entries.forEach(entry => {
        observer, entry

        // console.log(entry)


        if (entry.intersectionRatio > 0.35) {

          const image = new Image()
          image.sizes = el.sizes

          image.onload = () => {
            
            el.classList.remove('isBlur')
            el.srcset = el.dataset.srcset

            // vnode.context.thumbIsVisible = false
            setTimeout(() => { vnode.context.thumbIsVisible = false }, 220);

            observer.unobserve(el)
          }
          
          image.srcset = el.dataset.srcset
        }

      })
    }
    
    let observerImage = new IntersectionObserver(callbackImage, {root: null, rootMargin: '0px', threshold: 0.35});
    observerImage.observe(el)

    }, 300);

  }

};

export default {
  LazyLoading,
};

// Make it available globally.
Vue.directive('lazyloading', LazyLoading);