import Vue from 'vue';

const LazyLoadingProject = {
	inserted(el, binding, vnode) {
    el, binding, vnode

    setTimeout(() => { 

    let callbackImage = function(entries, observer){
      entries.forEach(entry => {
        observer, entry

        if (entry.intersectionRatio > 0) {
          vnode.context.isVisible = true
        }  

      })
    }
    
    let observerImage = new IntersectionObserver(callbackImage, {root: null, rootMargin: '0px', threshold: 0});
    observerImage.observe(el)

    }, 300);

  }

};

export default {
  LazyLoadingProject,
};

// Make it available globally.
Vue.directive('lazyloadingproject', LazyLoadingProject);

