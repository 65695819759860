<template>

  <div class="ElementMainSlider" @click="setIndex(1)" @mouseenter="mouseEnter()">

    <div class="ElementMainSlider--container" :style="{width: whatContainerWidth}">

      <div  v-for="(element, index) in content" :key="index"
      :data-indexElement="indexElement"
      :data-indexMedia="indexMedia[index]"
      class="ElementMainSlider--container--img-container" 
      :style="{width: whatWidthElement, transform: whatTranslate}">

        <!-- {{indexMedia[index]}} -->

        <div class="ElementMainSlider--container--img-container--content">

        <!-- <img class="ElementMainSlider--container--img-container--content--img isBlur" -->
        <img class="ElementMainSlider--container--img-container--content--img" 
        v-lazyloading
        :src="element.thumb"
        :data-srcset="element.srcset"
        sizes="30vw"
        ref="imageSlider">
        <!-- sizes="80vw" -->

        <transition name="fadeViewThumb" appear mode="out-in">

        <!-- <img class="ElementMainSlider--container--img-container--content--thumb isBlur" -->
        <img class="ElementMainSlider--container--img-container--content--thumb" 
        :src="element.thumb"
        v-show="thumbIsVisible">   

        </transition>

        </div>

      </div> 

    </div>
  </div>

</template>

<script>
import LazyLoading from '@/directives/LazyLoading';

export default {
  name: 'ElementMainSlider',
  directives:{
    LazyLoading
  },
  props: ['content', 'context', 'indexElement'],
  data: function(){
    return {
      index: 0,
      thumbIsVisible: true,
      indexMedia: []
    }
  },
  components: {},
  computed:{
    whatWidthElement: function(){
      let widthElement = Number(this.content[0].width)

      // return 'calc((100vw - 2vw) / 12 * +' + widthElement + ')'
      // calc(12 * (100% / 12))
      // return 'calc((75vw - 2vw) / 12 * +' + widthElement + ')'

      return 'calc(((100vw / 12 * 8) - 2vw) / 12 * +' + widthElement + ')'

    },
    whatElementsVisible: function(){

      let widthElement = Number(this.content[0].width)
      let elementVisible = 12 / widthElement

      return Math.floor(elementVisible)
    },
    whatContainerWidth: function(){

      return this.content.length * 100 + '%'
    },
    whatTranslate(){
      // returnWidth = (position_right - position_left) * (100 / 12) + '%' // RowNormal
      let returnTranslate = this.content.length * 100 / this.content.length * this.index

      return 'translate(-' + returnTranslate + '%)'
    }
  },
  methods: {

    mouseEnter: function(){
      // this.thumbIsVisible = false

      this.$refs.imageSlider.forEach((el) => {
        const image = new Image()
        image.sizes = el.sizes
        image.onload = () => {
          el.srcset = el.dataset.srcset
        }
        image.srcset = el.dataset.srcset
      })
    },
    
    setIndex: function(direction){

      let maxIndex = this.content.length - this.whatElementsVisible

      if ( this.index >= maxIndex){
        // console.log('maxIndex')
        // this.index = this.index + direction
      }else {
        // console.log(this.index)
        this.index = this.index + direction
      }
    }
  },
  created(){
    // console.log(this.content)

    this.content.forEach((element, index)=> {
      element
      this.indexMedia[index] = this.$store.indexMedia(this.content)
    })
  }
  
}
</script>

<style lang="scss">
@import '@/css/variables';

.fadeViewThumb-leave-active{
  transition: all .3s ease;
}

.fadeViewThumb-leave-to{
  transition: all .3s ease;
  opacity: 0;
  // filter: blur(10px);
}


.ElementMainSlider{
  width: 100%;
  // background-color: green;
  // overflow: hidden;

  &--container{
    display: flex;
    // width: 600%; 
    padding-left: calc(var(--home-esp-bloc-small) / 2);

    &--img-container{

      // width: calc((100vw - 2vw) / 12 * 10);

      transition: all 0.25s ease;
      // padding-left: vw;
      
      &--content{
        position: relative;
        // width: 100%;

        &--img{
          width: calc(100% - var(--home-esp-bloc-small) / 2);
          // padding: 1vw;
          object-fit: cover;

          border-radius: var(--home-round-small);

          // transition: all .5s ease;

          &.isBlur{
            // filter: blur(3px);
          }
        }

        &--thumb{
          position: absolute;
          width: calc(100% - var(--home-esp-bloc-small) / 2);
          // padding: 1vw;
          object-fit: cover;

          border-radius: var(--home-round-small);

          top: 0;
          left: 0;

          z-index: 70;
        }

      }
    }
  }
}

</style>