<template>

  <div class="CursorMain">

    <div class="CursorMain--container">
      <div class="CursorMain--container--cursor" ref="cursor" :style="{left:cursorX + 'px', top: cursorY + 'px' }"></div>
    </div>

  </div>

</template>

<script>

export default {
  name: 'InfoMain',
  props: ['content', ],
  data: function(){
    return {
      cursorX: '',
      cursorY: '',

      displayCount: 1,
      timeIsActiv: true

    }
  },
  watch: {

    displayCount: function(val){

      // console.log(val)

      if (val > 1){
        this.$refs.cursor.classList.remove('isMoving')

        if (val > 10){
          // console.log('MAX')
          this.removeTimer()
          this.timeIsActiv = false
        }

      }else {
        this.$refs.cursor.classList.add('isMoving')
      }
    }


  },
  components: {
  },
  computed:{

  },
  methods: {

    mouseMoveListener(e){

      this.cursorX = e.clientX
      this.cursorY = e.clientY

      if (this.displayCount > 10 && this.timeIsActiv == false){
        this.setTimer()
      }
      this.displayCount = 0
    },


    setTimer: function(){
      this.timerInterval = setInterval(this.addTime , 250);
    },
    removeTimer: function(){
      clearInterval(this.timerInterval)
    },
    addTime: function(){
      this.displayCount = this.displayCount + 1
    },




  },
  created(){
    // console.log(this.content)
    this.setTimer()
    document.addEventListener('mousemove', this.mouseMoveListener)
  }
  
}
</script>

<style lang="scss">
@import '@/css/variables';

.CursorMain{
  position: relative;
  width: 100%;
  height: 100%;

  pointer-events: none;

  z-index: 1000;
  // mix-blend-mode: exclusion;



  &--container{
    z-index: 1000;
    width: 100%;
    height: 100%; 


    &--cursor{
      z-index: 1000;
      position: absolute;
      width: 35px;
      height: 35px; 

      border-radius: 50%;

      transform: translate(-50%,-50%);

      // backface-visibility: hidden;
      // background-color: white;
      // color: white;
      // mix-blend-mode: exclusion;

      // margin: -15px 0 0 -15px;

      transition: transform 0.4s ease;

      backdrop-filter: blur(6px);
      webkit-backdrop-filter: blur(6px);
      background-color: rgba(50,50,50,0.7);

      &.isMoving{
        transform: translate(-50%, -50%) scale(0.9);
      }
    }
  }
}


</style>