<template>
  <div class="Projet" 
  :style="{'background-color' : whatColor()}"
  v-lazyloadingproject>

    <div class="Projet--legende-container">
      <Legende :content="content.info" :index="index" 
      :viewerDisplay="viewerDisplay"
      :initDisplay="initDisplay"></Legende>
    </div>

    <div class="Projet--container" :class="whatBlur" v-show="isVisible">

      <div class="Projet--container--row" v-for="(row, index) in content.rows" :key="index">

      <template v-if="row.category =='row1'">
        <RowMain 
        :content="row" 
        :index="index" 
        @send-viewer-state="sendViewerState"
        :viewerDisplay="viewerDisplay"></RowMain>
      </template>

      <template v-if="row.category =='colomn'">
        <ColomnMain 
        :content="row" 
        :index="index" 
        @send-viewer-state="sendViewerState"
        :viewerDisplay="viewerDisplay"></ColomnMain> 
      </template>
  
      </div>

    </div>

  </div>
</template>

<script>
import LazyLoadingProject from '@/directives/LazyLoadingProject';

import Legende from '@/components/project/Legende.vue'
import RowMain from '@/components/project/RowMain.vue'
import ColomnMain from '@/components/project/ColomnMain.vue'

export default {
  name: 'Projet',
  props: ['content', 'context', 'index', 'infoDisplay', 'viewerDisplay', 'initDisplay'],
  components: {
    Legende,
    RowMain,
    ColomnMain
  },
  directives:{
    LazyLoadingProject
  },
  data: function(){
    return {
      isBlur: true,

      isVisible: false
    }
  },
  watch:{
    // infoDisplay: function(val){
    //   if (val){
    //     this.isBlur = true
    //   }else {
    //     setTimeout(() => { this.isBlur = false }, 300);
    //   }
    // },
    viewerDisplay: function(val){
      if (val.isActiv){
        this.isBlur = true
      }else {
        setTimeout(() => { this.isBlur = false }, 100);
      }
    }
  },
  computed:{
    whatBlur: function(){
      if (this.isBlur){
        return 'isBlur'
        // return ''
      }else {
        return ''
      }
    }
  },
  methods: {
    whatColor: function(){
      if (this.index%2 == 0){
        return 'var(--color)'
      }else{
        return 'var(--color-lighten)'
      }
    },
    sendViewerState: function(payload){
      this.$emit('send-viewer-state', payload)
    },
  },
  created(){

    if (this.index < 1 ){
      this.isVisible = true
    }else {
      this.isVisible = false
    }

  },
  mounted(){
  }
  
}
</script>

<style lang="scss">
@import '@/css/variables';

.Projet{
  box-shadow: 10px 0px 10px 0 rgba(180, 180, 180, 0.8);
  position: relative;
  width: 100%;
  height: auto;
  min-height: 10vh;
  padding-top: calc(var(--home-esp-bloc-small) / 2);

  cursor: none;

  &--container{
    width: 100%;

    overflow: hidden;
    transition: filter .3s ease;

    cursor: none;

    &.isBlur{
      filter: blur(6px);
    }

    &--row{
      width: 100%;
    }
  }

  &--legende-container{
    position: absolute;
    width: var(--legende-width);
    left: calc(100% - var(--legende-width));
    height: calc(100% - var(--home-esp-bloc-small));
    pointer-events: none;

    z-index: 78;
  }
}

</style>