<template>

  <div class="RowMain">

    <!-- RowMain -->
    <!-- <pre>{{content}}</pre> -->
    <!-- {{content.categorie}} -->

    <div class="RowMain--elements-container" :class="'RowMain-' + context">

      <!-- Row1 - nombre d'élements: {{content.elements.length}} -->
  
      <ElementMain
      v-for="(element, index) in content.elements"
      :key="index"
      :content="element"
      :context="context"
      :colomn_width="colomn_width"
      :index="index"
      :previousElementPositionEnd="previousElementPositionEnd(index, content.elements.length)"
      @send-viewer-state="sendViewerState"
      :viewerDisplay="viewerDisplay">
      </ElementMain>

    </div>

    <br><br>

  </div>

</template>

<script>
import ElementMain from '@/components/project/rowmain/ElementMain.vue'

export default {
  name: 'RowMain',
  props: ['content', 'context', 'colomn_width', 'index', 'viewerDisplay'],
  components: {
    ElementMain
  },
  data: function(){
    return {
    }
  },

  computed:{},
  methods: {
    sendViewerState: function(payload){
      this.$emit('send-viewer-state', payload)
    },

    previousElementPositionEnd: function(index, totalLength){

      if( index == 0 || totalLength == 1){
        return 'first-element'
      }else {
        return this.content.elements[index - 1].position_right
      }

    }

  },
  created(){
    // console.log(this.content)
  }
  
}
</script>

<style lang="scss">
@import '@/css/variables';

.RowMain{

  width: 100%;
  height: auto;

  &--elements-container{
    
    display: flex;
    width: calc(100% - var(--home-esp-bloc-small));
    // margin-left: 0.5vw;
    margin-left: calc(var(--home-esp-bloc-small) / 2);
  }
}

.RowMain-colomn-left{
  width: calc(100% - var(--home-esp-bloc-small));
}

.RowMain-colomn-right{
  width: calc(100% - var(--home-esp-bloc-small));
}

</style>