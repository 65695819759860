<template>

  <div class="ColomnMain">

    <!-- ColomnMain -->

    <!-- {{content.colomns.left}} -->
    <!-- {{content.colomns.right}} -->
    <!-- <pre>{{content}}</pre> -->

    <div class="ColomnMain--container">


    <div class="ColomnMain--container--left" :style="{width: whatColomnWidth('left')}">
      <!-- left -->
      <!-- <pre>{{content.colomns.left}}</pre> -->

      <RowMain 
      v-for="(row, index) in content.colomns.left" 
      :key="index"
      :content="row" 
      :context="'colomn-left'"
      :index="index"
      :colomn_width="whatColomnGrid('left')"
      @send-viewer-state="sendViewerState">
      </RowMain>

    </div>

    <div class="ColomnMain--container--right" :style="{width: whatColomnWidth('right')}">
      <!-- right -->
      <!-- <pre>{{content.colomns.right}}</pre> -->

      <RowMain 
      v-for="(row, index) in content.colomns.right" 
      :key="index"
      :content="row" 
      :context="'colomn-right'"
      :colomn_width="whatColomnGrid('right')"
      :index="index"
      @send-viewer-state="sendViewerState">
      </RowMain>

    </div>


    </div>

  </div>

</template>

<script>
import RowMain from '@/components/project/RowMain.vue'

export default {
  name: 'ColomnMain',
  props: ['content', 'context'],
  components: {
    RowMain
  },
  data: function(){
    return {
    }
  },
  computed:{},
  methods: {

    whatColomnGrid: function(colomn){
      let colomnGrid 
      
      if (colomn == 'left'){
        colomnGrid = this.content.colomn1_position_end - this.content.colomn1_position_start
      }
      if (colomn == 'right'){
        colomnGrid = this.content.colomn2_position_end - this.content.colomn2_position_start
      }

      return colomnGrid
    },

    whatColomnWidth: function(colomn){
      let whatWidth

      if (colomn == 'left'){

        // let colomnGrid = this.content.colomn1_position_end - this.content.colomn1_position_start

        whatWidth = 'calc(' + this.whatColomnGrid(colomn) + '* (100% / 12))'

      }

      if (colomn == 'right'){

        // let colomnGrid = this.content.colomn2_position_end - this.content.colomn2_position_start

        whatWidth = 'calc(' + this.whatColomnGrid(colomn) + '* (100% / 12))'

      }

      return whatWidth
    },

    sendViewerState: function(payload){
      this.$emit('send-viewer-state', payload)
    },
  },
  created(){
    // console.log(this.content)
  }
  
}
</script>

<style lang="scss">
@import '@/css/variables';

.ColomnMain{
  width: 100%;

  &--container{
    display: flex;

    &--left{
      width: calc(6 * (100% / 12));
    }

    &--right{
      width: calc(6 * (100% / 12));
    }
  }
}

</style>