<template>


  <div class="viewer-wrapper">
    <div class="viewer-wrapper--sticky-container">
      <div class="viewer-wrapper--sticky-container--sticky">
        
        <div class="Legende" :class="whatBlur" v-show="!isHiding">
          <div class="Legende--container" @mouseenter="mouseEnter()" @mouseleave="mouseLeave()">
            
            <div class="Legende--container--title">{{content.title}}</div>

             <transition name="toggleDrawer" mode="out-in">
             <div class="Legende--container--text-content" v-if="textIsOpen"><!-- v-if="textIsOpen" -->
              <div class="Legende--container--text-content--description" v-html="content.description"></div>
              <div class="Legende--container--text-content--technical" v-html="content.technical"></div>
              <div class="Legende--container--text-content--credits" v-html="content.credits"></div>
             </div>
             </transition>

          </div>
        </div>

      </div>
    </div>
  </div>


</template>

<script>

export default {
  name: 'Legende',
  props: ['content', 'index', 'context', 'viewerDisplay', 'initDisplay'],
  data: function(){
    return {
      textIsOpen: false,
      isBlur: true,

      isHiding: false
    }
  },
  watch:{
    viewerDisplay: function(val){
      // if (val.isActiv && this.index == val.indexProjectActiv){
      //   setTimeout(() => { this.textIsOpen = true }, 100);
      // }else {
      //   this.textIsOpen = false
      // }

      if (val.isActiv && this.index == val.indexProjectActiv){
        this.isHiding = false
        setTimeout(() => { this.textIsOpen = true }, 100);

      }else if (val.isActiv && this.index != val.indexProjectActiv ) {
        this.isHiding = true

      }else {
        this.textIsOpen = false
        this.isHiding = false
      }

    },
    initDisplay: function(val){
      if (val){
        this.isBlur = true
      }else {
        this.isBlur = false
      }
    }
  },
  components: {},
  computed:{
    whatBlur: function(){
      if (this.isBlur){
        return 'isBlur'
      }else {
        return ''
      }
    }
  },
  methods: {
    mouseEnter: function(){
      if (!this.viewerDisplay.isActiv){
        this.textIsOpen = true
      }
    },
    mouseLeave: function(){
      if (!this.viewerDisplay.isActiv){
        this.textIsOpen = false
      }
    }
  },
  created(){

  }
}

</script>

<style lang="scss">
@import '@/css/variables';

.toggleDrawer-enter-active{
  transition: max-height 0.3s ease, opacity 0.5s ease;
  max-height: 600px;
}

.toggleDrawer-leave-active{
  // transition: all .3s;

  transition: max-height 0.3s ease, opacity 0.2s ease;
  max-height: 600px;
}

.toggleDrawer-enter, .toggleDrawer-leave-to {
  max-height: 0px;
  opacity: 0;
  // filter: blur(5px);
}

.viewer-wrapper{
  display: flex;
  flex-direction: column;
  height: 100%;

  margin-top: calc(var(--home-esp-bloc-small) / 2);

  &--sticky-container{
    flex-grow:1;
    flex-shrink: 1;
    flex-basis: auto;

    &--sticky{
      position: sticky;
      margin-right: var(--home-esp-bloc-small);
      // height: 10vh;
      top: var(--home-esp-bloc-small);
      // top: 0;


    }
  }
} 

.Legende{



  backdrop-filter: blur(20px);
  webkit-backdrop-filter: blur(20px);
  background-color: rgba(255,255,255,0.6);


  border-radius: 10px;

  font-size: var(--home-font-labeur);

  z-index: 78;

  width: 100%;
  height: auto;

  position: relative;


  pointer-events: auto;
   

  transition: filter .6s ease;

  box-shadow: -0.1em 0.1em 0.3em rgba(180, 180, 180, 0.5);

  &.isBlur{
    filter: blur(6px);
  }

  &--container{
    padding-left: calc( 1.5 * var(--home-esp-font-small));
    padding-right: var(--home-esp-font-small);
    line-height: 1.1;

    padding-top: var(--home-esp-font-small);
    padding-bottom: var(--home-esp-font-small);

  }
}

.Legende--container--text-content{
  padding-top: var(--home-esp-font-small);
  line-height: 1.2;

  & em {
    font-family: 'ital';
  }

  &--description{
    border-top: var(--home-border-size) solid;
    padding-bottom: var(--home-esp-font-small);
    padding-top: var(--home-esp-font-small);
  }
  &--credits{
    border-top: var(--home-border-size) solid;
    padding-top: var(--home-esp-font-small);
  }
  &--categories{
    border-top: var(--home-border-size) solid;
    padding-bottom: var(--home-esp-font-small);
    padding-top: var(--home-esp-font-small);
  }

  &--technical{
    border-top: var(--home-border-size) solid;
    padding-bottom: var(--home-esp-font-small);
    padding-top: var(--home-esp-font-small);
  }
}

</style>