<template>

  <div class="ElementMain"
  :style="{'margin-top': whatMarginTop(), 'margin-left': whatMarginLeft(), 'width': whatWidth() + '%'}">

    <!-- categorie : {{content.categorie}}, -->
    <!-- position_left : {{content.position_left}}, -->
    <!-- position_right : {{content.position_right}} -->

    <!-- {{indexElement}}, {{content.index_element}} -->

    <component 
    :is="whatElement(content.categorie)" 
    :sizes="whatSizes()"
    :content="content.content"
    :indexElement="indexElement" 
    @send-viewer-state="sendViewerState"
    :viewerDisplay="viewerDisplay">
    </component>

  </div>

</template>

<script>
import ElementMainImage from '@/components/project/rowmain/elementmain/ElementMainImage.vue'
import ElementMainSlider from '@/components/project/rowmain/elementmain/ElementMainSlider.vue'
import ElementMainVideo from '@/components/project/rowmain/elementmain/ElementMainVideo.vue'

export default {
  name: 'ElementMain',
  props: ['content', 'context', 'colomn_width', 'index', 'previousElementPositionEnd', 'viewerDisplay'],
  components: {
    ElementMainImage,
    ElementMainSlider,
    ElementMainVideo
  },
  data: function(){
    return {
      indexElement: ''
    }
  },

  computed:{},
  methods: {
    sendViewerState: function(payload){
      this.$emit('send-viewer-state', payload)
    },

    whatElement: function(categorie){
      let returnCategorie

      if (categorie == "image"){
        returnCategorie = 'ElementMainImage'
      }
      if (categorie == "slider"){
        returnCategorie = 'ElementMainSlider'
      }
      if (categorie == "video"){
        returnCategorie = 'ElementMainVideo'
      }

      // console.log(returnCategorie)
      return returnCategorie
    },

    whatColomnFactor: function(){

       return 12 / this.colomn_width
    },

    whatSizes: function(){
      let returnSizes

      if (this.context == "colomn-left" || this.context == "colomn-right"){

        returnSizes = this.whatWidth() / 2

      }else {

        returnSizes = this.whatWidth()

      }

      return returnSizes
    },

    whatWidth: function(){
      let returnWidth

      let position_left = Number(this.content.position_left)
      let position_right = Number(this.content.position_right)
    
      if (this.context == "colomn-left" || this.context == "colomn-right"){

        returnWidth = (position_right - position_left) * this.whatColomnFactor() * (100 / 12)

         // console.log(returnWidth)

      }else{
        returnWidth = (position_right - position_left) * (100 / 12) // RowNormal
        // returnWidth = (position_right - position_left) * (100 / 9) + '%' // RowNormal
      }

      return returnWidth
    },
    whatMarginTop: function(){
      // console.log(this.content.margin)

      let returnMarginTop

      if (this.content.margin == 'small'){
        // returnMarginTop = '3.25vw'

        // 2 * bloc-esp => 2 vw
        
        // legende
        //  padding top + padding bottom => 2 * var(--home-esp-font-small)
        //  font => var(--home-font-labeur)

        // let blocsEsp = '(2 * var(--home-esp-bloc-small)) +'
        // let paddingLegende = '(2 * var(--home-esp-font-small)) +'
        // let fontSize = 'var(--home-font-labeur)'

        // returnMarginTop = 'calc( (2 * var(--home-esp-bloc-small)) + (2 * var(--home-esp-font-small)) + var(--home-font-labeur) )'

        returnMarginTop = 'calc( (2 * var(--home-esp-bloc-small)) + (2 * var(--home-esp-font-small)) + var(--home-font-labeur) - (var(--home-esp-bloc-small)) )'

      }

      return returnMarginTop
    },
    whatMarginLeft: function(){
      let returnMarginLeft

      let position_left = Number(this.content.position_left)

      if (this.previousElementPositionEnd == "first-element"){

        if (this.context == "colomn-left" || this.context == "colomn-right"){
          returnMarginLeft = position_left * this.whatColomnFactor() * (100 / 12) + '%'
        }else{
          returnMarginLeft = position_left * (100 / 12) + '%'
        }

      }else {

        let newMargin = position_left - this.previousElementPositionEnd

        if (this.context == "colomn-left" || this.context == "colomn-right"){
          returnMarginLeft = newMargin * 2 * (100 / 12) + '%'
        }else{
          returnMarginLeft = newMargin * (100 / 12) + '%'
        }
      }

      return returnMarginLeft
    }
  },
  created(){
    // console.log(this.previousElementPositionEnd)

    this.indexElement = this.$store.indexElement(this.content)
  }
  
}
</script>

<style lang="scss">
@import '@/css/variables';

.ElementMain{
  // cursor: var(--cursor-arrow-right);
  // cursor: pointer;
}

</style>