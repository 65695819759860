<template>

  <div class="InfoMain">

  <div class="InfoMain--container" ref="container">
    <div class="InfoMain--container--description" ref="description" :style="{position: BlocPosition}">
      <p>Design & développement.</p>
      <p>Pour toute demande, n’hésitez pas à me contacter par mail.</p>
      <p>Portfolio et CV sur demande.</p>
     </div>
     <div class="InfoMain--container--contact" ref="contact" :style="{position: BlocPosition}">
      <template v-if="context != 'mobile'">
      <p>Julien Gatard<br>12, rue de l'industrie<br>75013 Paris, France</p>
      </template>
      <!-- <p><span>Mail</span>, <span>Instagram</span>, <span>Linkedin</span></p> -->
      <p><a href="mailto:info@juliengatard.fr">info@juliengatard.fr</a></p>
    </div>
  </div>

  </div>


</template>

<script>

export default {
  name: 'InfoMain',
  props: ['content', 'context'],
  data: function(){
    return {
      BlocPosition: ''
    }
  },
  components: {},
  computed:{},
  methods: {
    setBlocPosition: function(){

      let containerHeight = this.$refs.container.getBoundingClientRect().height
      let descriptionHeight = this.$refs.description.getBoundingClientRect().height
      let contactHeight = this.$refs.contact.getBoundingClientRect().height

      let endPoint = containerHeight - (descriptionHeight + contactHeight )

      if (endPoint > 0){ 
        this.BlocPosition = 'absolute'

      }else { 
        this.BlocPosition = 'relative'
      }
    },
    onResize: function(){
      this.setBlocPosition()
    }
  },
  created(){
    window.addEventListener('resize', this.onResize)
  },
  mounted(){
    this.setBlocPosition()
  }
}

</script>

<style lang="scss">
@import '@/css/variables';

.InfoMain{
  width: 100%;
  height: 100%;
  line-height: 1.05;
  font-size: var(--info-font-main);

  &--container{
    width: 100%;
    height: 100%;

    overflow: scroll;

    &--description{
      position: absolute;
      width: calc(90% - ( 2 * var(--home-esp-bloc-small)));
      top: 0;
      left: 0;
      padding: var(--home-esp-bloc-small);

      font-size: var(--home-font-main);

      & p {
        margin-top: var(--home-esp-bloc-small);
        overflow-wrap: anywhere;
        @supports ( hyphens: auto ) {
          hyphens: auto;
          -webkit-hyphens: auto;
          -moz-hyphens: auto;
          -ms-hyphens: auto;
        }
      }

      & p:first-child{
        margin-top: 0vw;
      }

    }

    &--contact{

      position: absolute;
      width: calc(94% - ( 2 * var(--home-esp-bloc-small)));
      bottom: 0;
      left: 0;
      padding: var(--home-esp-bloc-small);

      font-size: var(--home-font-second);

      & a{
        text-decoration: underline;
      }

      & p {
        margin-top: var(--home-esp-bloc-small);
      }

      & p:first-child{
        margin-top: 0vw;
      }

    }
  }




  // &--infos-container{
  //   position: fixed;
  //   // width: calc(3 * (100% / 12));
  //   width: calc(4 * (100% / 12));
  //   height: 100%;
  //   background-color: var(--color-lighter);

  //   // background-color: rgba(20,120,180,1);
  //   // color: white;

  //   transition: filter .3s ease;
  //   &.isBlur{
  //     filter: blur(15px);
  //   }

  //   &--content{
  //     line-height: 1.05;
  //     font-size: var(--info-font-main);


  //     &--description{
  //       position: absolute;
  //       width: calc(90% - ( 2 * var(--home-esp-bloc-small)));
  //       top: 0;
  //       left: 0;
  //       padding: var(--home-esp-bloc-small);

  //       & p {

  //         margin-top: var(--home-esp-bloc-small);
  //         overflow-wrap: anywhere;
  //         @supports ( hyphens: auto ) {
  //           hyphens: auto;
  //           -webkit-hyphens: auto;
  //           -moz-hyphens: auto;
  //           -ms-hyphens: auto;
  //         }
  //       }

  //       & p:first-child{
  //         margin-top: 0vw;
  //       }
  //     }

  //     &--contact{
  //       position: absolute;
  //       width: calc(100% - ( 2 * var(--home-esp-bloc-small)));
  //       bottom: 0;
  //       left: 0;
  //       padding: var(--home-esp-bloc-small);

  //       & span{
  //         text-decoration: underline;
  //       }

  //       & p {
  //         margin-top: var(--home-esp-bloc-small);
  //       }

  //       & p:first-child{
  //         margin-top: 0vw;
  //       }
  //     }
  //   }
  // }

}

</style>