<template>

  <div class="Viewer">

    <div class="Viewer--container">

      <div class="Viewer--container--background" @click="close()"></div>

      <template v-if="content[indexMedia].categorie == 'image' || content[indexMedia].categorie == 'slider'">

      <div class="Viewer--container--img-container" :class="whatOverflow" @click="close()" ref="container">
        <img class="Viewer--container--img-container--img" 
        :srcset="content[indexMedia].content.srcset"
        :class="whatImgPosition()"
        sizes="80vw">
      </div>

      </template>

      <template v-if="content[indexMedia].categorie == 'video'">
        
        <div class="Viewer--container--img-container" :class="whatOverflow" @click="close()">

          <video class="Viewer--container--img-container--img" 
          loop muted playsinline autoplay ref="videoplayer"
          :poster="content[indexMedia].content.thumb"
          :class="whatImgPosition()">
            <source :src="content[indexMedia].content.video" type="video/mp4">
          </video>

        </div>

      </template>

      <div class="Viewer--container--nav-container">
        <div class="Viewer--container--nav-container--left" @click="clickNav(-1)"><p>➔</p></div>
        <div class="Viewer--container--nav-container--right" @click="clickNav(1)"><p>➔</p></div>
      </div>


    </div>

  </div>

</template>

<script>

export default {
  name: 'Viewer',
  props: ['content','DOM_Tabs', 'DOM_ElementsMain', 'payload', 'type', 'context'],
  data: function(){
    return {
      indexProject: '',
      indexElement: '',
      indexMedia: '',

      scrollTopValue: ''
    }
  },
  watch: {},
  components: {},
  computed:{
    whatOverflow: function(){

      let returnOverflow

      if(this.content[this.indexMedia].content.viewer_position == "scroll"){
        returnOverflow = 'overflow-scroll'
      }else {
        returnOverflow = 'overflow-hidden'
      }

      return returnOverflow
    },
  },
  methods: {

    whatImgPosition: function(){

      // console.log(this.content[this.indexMedia])
      // console.log(this.content[this.indexMedia].content.viewer_position)

      return 'viewer-' + this.content[this.indexMedia].content.viewer_position
    },

    close: function(){

      // console.log('Viewer', 'disable viewer')

      let newState = {
        isActive: false,
      }

      this.$emit('send-viewer-state', newState)
    },

    clickNav: function(direction){

      let controlIndex = this.indexMedia

      controlIndex = controlIndex + direction

      if (controlIndex < 0 ){
        // console.log('MIN')
        //

      }else if (controlIndex > (this.content.length - 1) ){

        // console.log('MAX')
        this.indexMedia = 0
        this.setData()
        this.emitState()

      }else {

        this.indexMedia = this.indexMedia + direction

        this.setData()
        this.emitState()

      }
    },

    setScrollTopValue: function(){
      let ProjectCible = this.DOM_Tabs.projects[this.indexProject]
      let ElementCible = this.DOM_Tabs.ElementsMain[this.indexElement]
      let newScrollTopValue = ProjectCible.offsetTop + ElementCible.parentNode.offsetTop

      this.scrollTopValue = newScrollTopValue
    },

    setData: function(){

      this.indexElement = this.content[this.indexMedia].index_element
      this.indexProject = this.content[this.indexMedia].index_projet
      this.setScrollTopValue()
    },  

    emitState: function(){

      let newState = {
        isActive: true,
        indexMedia: this.indexMedia,
        indexElement: this.indexElement,
        indexProject: this.indexProject,
        scrollTopValue: this.scrollTopValue
      }

      this.$emit('send-viewer-state', newState)  
    },

    setInitAnim: function(){

      if (this.content[this.indexMedia].categorie == 'image'){

      const image = new Image()
      image.sizes = '80vw'

      image.onload = () => {
        setTimeout(() => { 
          this.$el.classList.add('isLoaded') 
          this.setInitScroll()
        }, 10);
      }

      image.srcset = this.content[this.indexMedia].content.srcset

      }else {
        setTimeout(() => { 
          this.$el.classList.add('isLoaded')
          this.setInitScroll()
        }, 10);
      }
    },

    setInitScroll: function(){

      setTimeout(() => {

        if (this.content[this.indexMedia].content.viewer_position == 'scroll'){

          this.$refs.container.scrollTo({
            top: 1,
            left: 0,
            behavior: 'smooth'
          });
        }

      }, 250);

    }
  },
  created(){
    this.indexMedia = this.payload
    this.setData()
    this.emitState()
  },

  mounted(){

    this.setInitAnim()


    // this.setScrollTopValue()

    // setTimeout(() => { this.$el.classList.add('isLoaded') }, 50);

    // setTimeout(() => {

    //   if (this.content[this.indexMedia].content.viewer_position == 'scroll'){

    //     this.$refs.container.scrollTo({
    //       top: 1,
    //       left: 0,
    //       behavior: 'smooth'
    //     });
    //   }

    // }, 250);
  }
}

</script>

<style lang="scss">
@import '@/css/variables';
@import '@/css/transition';

.Viewer{
  position: fixed;
  width: 100%;
  height: 100vh;
  z-index: 70;
  // pointer-events: none;

  // transform: translate(0, 100%);
  transition: top .2s ease;

  top: 100vh;

  &.isLoaded{
    top: 0vh;
    // transform: translate(0, 15%);
  }


  &--container{
    position: absolute;

    width: 100%;
    height: 100%;

    z-index: 70;

    &--background{

      position: absolute;
      width: 100%;
      height: 100%;
      // opacity: 0.1;

      z-index: 68;

      // background-color: rgba(255,255,255,0.6);
      // backdrop-filter: blur(10px);
      // webkit-backdrop-filter: blur(10px);

      // transition: backdrop-filter 0.2s;
      // transition: all .6s ease;
      // background-color: red;
    }

    &--img-container{
      z-index: 70;
      position: absolute;
      left: var(--home-esp-bloc-small);
      top: var(--home-esp-bloc-small);

      // max-width: calc(100% - 10% - (2 * 1vw));
      // max-height: calc( 100% - 2 * 1vw);

      width: calc(100% - (var(--legende-width) / 12 * 8) - (2 * var(--home-esp-bloc-small)));
      // height: calc( 100% - 2 * 1vw);

      max-width: calc(100% - (var(--legende-width) / 12 * 8) - (2 * var(--home-esp-bloc-small)));
      // max-height: calc( 100% - (2 * var(--home-esp-bloc-small)));

      // max-height: calc( 100% - (1 * var(--home-esp-bloc-small)));


      // max-height: calc( 100% );


      // width: calc(100% - (var(--legende-width) / 12 * 9) - (2 * var(--home-esp-bloc-small)));
      // height: calc( 100% - (2 * var(--home-esp-bloc-small)));

      // overflow: scroll;
      // overflow: hidden;

      // border-radius: var(--home-round-med);

      &.overflow-scroll{
        overflow: scroll;
        max-height: calc( 100% - (1 * var(--home-esp-bloc-small)));
        height: calc( 100% - (1 * var(--home-esp-bloc-small)));
      }

      &.overflow-hidden{
        overflow: hidden;
        max-height: calc( 100% - (2 * var(--home-esp-bloc-small)));
        height: calc( 100% - (2 * var(--home-esp-bloc-small)));
      }

      &--img{

        border-radius: var(--home-round-med);

        &.viewer-full{
          // width: 100%;
          // height: 100%;
          // object-fit: contain;
          // object-position: left top;

          max-width: 100%;
          max-height: 100%;

          object-fit: cover;


        }

        &.viewer-scroll{
          width: 100%;
          margin-bottom: var(--home-esp-bloc-small);
        }
      }
    }

    &--nav-container{
      position: absolute;

      width: calc((var(--legende-width) / 12 * 8) - var(--home-esp-bloc-small));
      height: 10vw;
      bottom: var(--home-esp-bloc-small);
      right: var(--home-esp-bloc-small);

      display: flex;
      justify-content: space-between;

      z-index: 70;

      &--left{
        height: 100%;
        width: 48%;

        backdrop-filter: blur(20px);
        webkit-backdrop-filter: blur(20px);
        background-color: rgba(255,255,255,0.6);
        border-radius: 10px;

        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        transform: rotate(180deg);

        font-size: 55px;
      }

      &--right{
        height: 100%;
        width: 48%;

        backdrop-filter: blur(20px);
        webkit-backdrop-filter: blur(20px);
        background-color: rgba(255,255,255,0.7);
        border-radius: 10px;

        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        font-size: 55px;
      }
    }
  }
}

</style>