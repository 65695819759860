import Vue from 'vue';

const LazyLoadingVideo = {
	inserted(el, binding, vnode) {
    el, binding, vnode

    setTimeout(() => { 

    let callbackImage = function(entries, observer){
      entries.forEach(entry => {
        observer, entry

        if (entry.intersectionRatio > 0) {
          // console.log('setisVisible')
          vnode.context.isVisible = true
          // el.play()
          // console.log('play', el)
        }

        if (entry.intersectionRatio == 0) {
          // el.pause()
          // console.log('setisNotVisible')
          vnode.context.isVisible = false
          // console.log('pause', el)
        }    

      })
    }
    
    let observerImage = new IntersectionObserver(callbackImage, {root: null, rootMargin: '0px', threshold: 0});
    observerImage.observe(el)

    }, 1000);

  }

};

export default {
  LazyLoadingVideo,
};

// Make it available globally.
Vue.directive('lazyloadingvideo', LazyLoadingVideo);

