<template>

  <!-- <div class="ElementMainImage" @mouseenter="mouseEnter()" @mouseleave="mouseLeave()"> -->
  <div class="ElementMainImage" 
  @click="click()" 
  @mouseenter="mouseEnter()" 
  :data-indexElement="indexElement"
  :data-indexMedia="indexMedia">

    <div class="ElementMainImage--container">

    <!-- {{indexMedia}} -->

    <!-- <img class="ElementMainImage--container--img isBlur" -->
    <img class="ElementMainImage--container--img"
    v-lazyloading
    :src="content.thumb"
    :data-srcset="content.srcset"
    :sizes="Math.round(sizes / 3) + 'vw'"
    ref="image">
    <!-- :sizes="Math.round(sizes / 2) + 'vw'" -->
    <!-- sizes="80vw" -->


    <transition name="fadeViewThumb" appear mode="out-in">

    <img class="ElementMainImage--container--thumb"
    :src="content.thumb"
    v-show="thumbIsVisible">

    </transition>

<!--     <img class="ElementMainImage--img isBlur"
    v-lazyloading
    :src="content.thumb"
    :srcset="content.srcset"
    sizes="80vw"> -->


    </div>

  </div>

</template>

<script>
import LazyLoading from '@/directives/LazyLoading';

export default {
  name: 'ElementMainImage',
  directives:{
    LazyLoading
  },
  props: ['content', 'context', 'indexElement', 'sizes'],
  data: function(){
    return {
      thumbIsVisible: true,
      indexMedia: ''
    }
  },
  components: {},
  computed:{},
  methods: {

    mouseEnter: function(){
      // this.thumbIsVisible = false
      // this.$refs.image.srcset = this.$refs.image.dataset.srcset

      // console.log(this.$refs.image.sizes)

      const image = new Image()
      image.sizes = this.$refs.image.sizes
      image.onload = () => {
        this.$refs.image.srcset = this.$refs.image.dataset.srcset
        this.thumbIsVisible = false
        // this.$refs.image.classList.remove('isBlur')
      }
      
      image.srcset = this.$refs.image.dataset.srcset

    },

    click: function(){

      let newState = {
        isActive: true,
        type: 'image',
        content: {
          type: 'image',
          indexElement: this.indexElement,
          indexMedia: this.indexMedia,
          media: this.content.srcset
        }
      }
      this.$emit('send-viewer-state', newState)
    },
    mouseLeave: function(){
      let newState = {
        isActive: false
      }

      this.$emit('send-viewer-state', newState)
    }
  },
  created(){
    // console.log('ElementMainImage', this.content)

    this.indexMedia = this.$store.indexMedia(this.content)
  }
  
}
</script>

<style lang="scss">
@import '@/css/variables';

.fadeViewThumb-leave-active{
  transition: opacity .3s ease;
  opacity: 1;
}

.fadeViewThumb-leave-to{
  transition: opacity .3s ease;
  opacity: 0;
}

.ElementMainImage{
  width: 100%;
  object-fit: cover;

  padding: calc(var(--home-esp-bloc-small) / 2);
  width: calc(100% - var(--home-esp-bloc-small));

  // overflow: hidden;
  position: relative;

  &--container{
    position: relative;
    width: 100%;

    &--img{
      // position: absolute;
      width: 100%;
      object-fit: cover;
      border-radius: var(--home-round-small);

      // transition: filter .5s ease;

      &.isBlur{
        // filter: blur(3px);
      }
    }

    &--thumb{
      position: absolute;
      width: 100%;
      object-fit: cover;
      border-radius: var(--home-round-small);

      top: 0;
      left: 0;  

      z-index: 70;

      &.isBlur{
        // filter: blur(3px);
      }
    }
  }
}

</style>