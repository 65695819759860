<template>
	<div class="Home">

		<div class="Home--cursor">
			<CursorMain></CursorMain>
		</div>

		<div class="Home--init" v-if="initDisplay" @click="setInitDisplay()"></div>

		<transition name="ViewerToggle" mode="out-in">
		<div class="Home--viewer" v-if="viewerDisplay">
			<Viewer 
			:content="page.viewer" 
			:DOM_Tabs="{projects: DOM_Projects, ElementsMain: DOM_ElementsMain}"
			:DOM_ElementsMain="DOM_ElementsMain"
			:payload="contentViewer" 
			@send-viewer-state="sendViewerState"></Viewer>
		</div>
		</transition>
		
		<div class="Home--infos-container" :class="whatBlur">
			<infoMain></infoMain>
		</div>


		<div class="Home--projects-container">

			<Project 
			v-for="(projet, index) in page.projets"
			:key="index"
			:content="projet"
			:index="index"
			:infoDisplay="infoDisplay"
			:initDisplay="initDisplay"
			:viewerDisplay="{isActiv: viewerDisplay, indexProjectActiv: indexProject}"
			@send-viewer-state="sendViewerInit">
			</Project>

		</div>

	</div>

</template>

<script>
import clientConfigs from '@/mixins/clientConfigs.js'
import smoothscroll from 'smoothscroll-polyfill';

import Project from '@/components/Project.vue'
import Viewer from '@/components/Viewer.vue'
import InfoMain from '@/components/InfoMain2.vue'

import CursorMain from '@/components/CursorMain.vue'

export default {
  name: 'Home',
  mixins: [clientConfigs],
	components: {
		Project,
		Viewer,
		InfoMain,
		CursorMain
	},
  data: function(){
    return {
      state : this.$store.state,

      isBlur: false,

      initDisplay: true,

      infoDisplay: false,
      scrollActive: false,

      viewerDisplay: false,
      contentViewer: '',
      typeViewer: '',

      DOM_Projects: '',
      DOM_ElementsMain: '',

      indexMedia: '',
      indexElement: '',
      indexProject: '',
      scrollTopValue: '',
    }
  },
	watch: {
		indexMedia: function(val){val},
		indexElement: function(val){val},
		indexProject: function(val){val},
		scrollTopValue: function(val){
			// console.log('HOME watch scrollTopValue', val)
			this.setViewerScrollTop(val)
		},
    viewerDisplay: function(val){
      if (val.isActiv){
        this.isBlur = true
      }else {
        this.isBlur = false
      }
    }
  },
	computed: {
		page: function () { return this.$store.state},

		whatBlur: function(){
			if (this.infoDisplay == true || this.viewerDisplay == true){
				return 'isBlur'
				// return ''
			}else {
				return ''
			}
		}
	},
	methods: {

		setInitDisplay: function(){
			this.initDisplay = false
		},

		setViewerScrollTop: function(scrollTopValue){ // polyfill

			// console.log('HOME setScrollViewer', scrollTopValue)
			setTimeout(() => { 
      this.$el.scrollTo({
        top: scrollTopValue,
        left: 0,
        behavior: 'smooth'
      });
      }, 300);
		},

		sendInfomainState: function(){
			if (this.infoDisplay == false){
				this.infoDisplay = true
			}else {
				this.infoDisplay = false
			}
		},

		sendViewerInit: function(payload){
			// console.log('HOME sendViewerInit', payload)
			if ( payload.isActive){
				this.viewerDisplay = true
				this.contentViewer = payload.content.indexMedia
			}
		},

    sendViewerState: function(payload){
      // console.log('HOME sendViewerState', payload)

			if ( payload.isActive){
				this.viewerDisplay = payload.isActive
				this.indexMedia = payload.indexMedia
				this.indexElement = payload.indexElement
				this.indexProject = payload.indexProject
				this.scrollTopValue = payload.scrollTopValue

			}else {
				this.viewerDisplay = false
				this.indexMedia = ''
				this.indexElement = ''
				this.indexProject = ''
				// this.scrollTopValue = ''
			}
      
    },

		setDOMTabs: function(){

			/* DOM_Project */
			this.DOM_Projects = this.$el.querySelectorAll('.Projet')

			/* DOM ELEMENTS*/
			this.DOM_ElementsMain = this.$el.querySelectorAll('.ElementMain')
		},

		setVideoLoading: function(){

			this.page.loading.forEach((element, index) => {
				element, index

				let video = document.createElement("video");
				video.src = element.video
				video.load()	

				// this.$el.appendChild(video)

				// console.log('video ', index, element.video, ' loaded?')
			})
		},



  },

	created(){
		smoothscroll.polyfill();
		
		let randomNumber = this.$tools.getRandom(this.page.colors.length)
		let randomColor = this.page.colors[randomNumber]
		this.$tools.setColorsActive(randomColor)

		
	},
	mounted(){

		// this.setVideoLoading()

    setTimeout(() => { this.setDOMTabs() }, 400);
    // console.log(this.page.mobile)
	}
}
</script>

<style lang="scss">
@import '@/css/transition';

/* */

.fadeView-enter-active, .fade-leave-active {
  transition: all 0.6s ease;
}

.fadeView-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
  // filter: blur(5px);
}

/* */

.RedirectShopCaseToggle-enter{
  // transition: transform .3s ease;
  // transform: translate(0, -100%);
}
.RedirectShopCaseToggle-enter-active {
  // transition: transform .3s ease;
}
.RedirectShopCaseToggle-enter-to{
  // transition: transform .3s ease;
  // transform: translate(0, 0%);
}

/* */

.ViewerToggle-enter{
  // transition: transform .3s ease;
  // transform: translate(0, 100%);
}
.ViewerToggle-enter-active {
  // transition: transform .3s ease;
}
.ViewerToggle-enter-to{
  // transition: transform .3s ease;
  // transform: translate(0, 0%);
}

.ViewerToggle-leave{
  transition: transform .3s ease;
  // top: 0vh;
  transform: translate(0, 0%);
}


.ViewerToggle-leave-active{
  transition: transform .3s ease;
}

.ViewerToggle-leave-to{
  transition: transform .3s ease;
  transform: translate(0, 100%);
}

// .RedirectShopCaseToggle-enter{
//   transition: transform .3s ease;
//   transform: translate(0, -100%);
// }
// .RedirectShopCaseToggle-enter-active {
//   transition: transform .3s ease;
// }
// .RedirectShopCaseToggle-enter-to{
//   transition: transform .3s ease;
//   transform: translate(0, 0%);
// }

.RedirectShopCaseToggle-leave{
  transition: transform .3s ease;
  // top: 0vh;
  transform: translate(0, 0%);
}


.RedirectShopCaseToggle-leave-active{
  transition: transform .3s ease;
}

.RedirectShopCaseToggle-leave-to{
  transition: transform .3s ease;
  transform: translate(0, -100%);
}

/* */

.Home{
	position: absolute;
	width: 100%;
	height: 100%;

	overflow: scroll; // overflow: hidden;
	
	font-size: var(--home-font-labeur);

	cursor: none;

	&--cursor{
		position: fixed;
		width: 100%;
		height: 100%;
		z-index: 500;
		pointer-events: none;
	}

	&--init{
		position: fixed;
		width: 100%;
		height: 100%;
		z-index: 500;
	}



	&--infos-container{
		position: fixed;
		// width: calc(3 * (100% / 12));
		width: calc(4 * (100% / 12));
		height: 100%;
		background-color: var(--color-lighter);

		// background-color: rgba(20,120,180,1);
		// color: white;

		transition: filter .3s ease;
		&.isBlur{
			filter: blur(6px);
		}

		&--content{
			line-height: 1.05;
			font-size: var(--info-font-main);

			&--description{
				position: absolute;
				width: calc(90% - ( 2 * var(--home-esp-bloc-small)));
				top: 0;
				left: 0;
				padding: var(--home-esp-bloc-small);

				& p {

					margin-top: var(--home-esp-bloc-small);
					overflow-wrap: anywhere;
					@supports ( hyphens: auto ) {
						hyphens: auto;
						-webkit-hyphens: auto;
						-moz-hyphens: auto;
						-ms-hyphens: auto;
					}
				}

				& p:first-child{
					margin-top: 0vw;
				}
			}

			&--contact{
				position: absolute;
				width: calc(100% - ( 2 * var(--home-esp-bloc-small)));
				bottom: 0;
				left: 0;
				padding: var(--home-esp-bloc-small);

				& span{
					text-decoration: underline;
				}

				& p {
					margin-top: var(--home-esp-bloc-small);
				}

				& p:first-child{
					margin-top: 0vw;
				}
			}
		}
	}

	&--projects-container{
		position: relative;
		transition: filter .3s ease;
		// width: calc(9 * (100% / 12));
		// left: calc(3 * (100% / 12));
		// 
		width: calc(8 * (100% / 12));
		left: calc(4 * (100% / 12));

		// box-shadow: 0 0 10px 0 #00000066;
		// box-shadow: -0.1em 0 1em grey;
		box-shadow: -0.1em 0 0.5em rgba(180, 180, 180, 0.8);



		&.isBlur{
			// filter: blur(15px);
		}
	}

	&--menu-container{
		position: absolute;
		width: 100%;
		height: 100%;
	}

	&--info-container{
		position: fixed;
		width: 100%;
		height: 100%;
		z-index: 80;
	}

	&--viewer{
		position: fixed;
		width: 100%;
		height: 100%;
		z-index: 50;
	}
}

</style>
